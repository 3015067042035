import React, { useEffect, useRef } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ChildPages from "../components/ChildPages";
import BackgroundImage from "gatsby-background-image";
import personStyles from "./person.module.css";
// import { Parallax, Background } from "react-parallax";
import Rellax from "rellax";
import SEO from "../components/seo";

/* can't get variable with static query, so using dynamic and exporting it */

export const query = graphql`
  query($slug: String!) {
    wordpressWpTeammember(slug: { eq: $slug }) {
      title

      content
      acf {
        image_for_bio {
          localFile {
            childImageSharp {
              fixed(width: 500, height: 300) {
                ...GatsbyImageSharpFixed
              }
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        headshot {
          localFile {
            childImageSharp {
              fixed(width: 200, height: 300) {
                ...GatsbyImageSharpFixed
              }
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const Person = (props) => {
  return (
    <Layout>
      <SEO title={props.data.wordpressWpTeammember.title} />
      <div className={`container ${personStyles.container}`}>
        <div className={`${personStyles.image}`}>
          {props.data.wordpressWpTeammember.acf.image_for_bio &&
          props.data.wordpressWpTeammember.acf.image_for_bio.localFile ? (
            // <img
            //   src={props.data.wordpressWpTeammember.acf.image_for_bio.source_url}
            //   alt={props.data.wordpressWpTeammember.title}
            // />
            <Img
              alt=""
              fixed={
                props.data.wordpressWpTeammember.acf.image_for_bio.localFile
                  .childImageSharp.fixed
              }
            />
          ) : (
            // <img
            //   src={props.data.wordpressWpTeammember.acf.headshot.source_url}
            //   alt={props.data.wordpressWpTeammember.title}
            // />
            // <Img
            //   alt=""
            //   fixed={
            //     props.data.wordpressWpTeammember.acf.headshot.localFile
            //       .childImageSharp.fixed
            //   }
            // />
            <span>No Photo</span>
          )}
        </div>
        <div className={personStyles.bio}>
          <h2>{props.data.wordpressWpTeammember.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.wordpressWpTeammember.content,
            }}
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default Person;
